import BasicSlideOver from '@/components/BasicSlideOver'
import ContentLoading from '@/components/ContentLoading'
import DefinitionList from '@/components/DefinitionList'
import GenericError from '@/components/GenericError'
import NotFound from '@/components/NotFound'
import { Spinner } from '@/components/Spinner'
import StatusTag from '@/components/StatusTag'
import { getExpiredStatus } from '@/enums/enums'
import FileDragAndDrop from '@/features/FileDragAndDrop'
import CopyToLeasing from '@/features/tax/CopyToLeasing'
import { TaxCaseProgressTimeLine } from '@/features/tax/TaxCaseProgressTimeLine'
import AcceptTaxCase from '@/features/tax/accept/AcceptTaxCase'
import TaxCaseAccepted from '@/features/tax/accept/TaxCaseAccepted'
import CaseAccepted from '@/features/tax/status/Acccepted'
import CaseCompleted from '@/features/tax/status/CaseCompleted'
import CaseExpired from '@/features/tax/status/CaseExpired'
import EmptyCalculationResult from '@/features/tax/status/EmptyCalculationResult'
import InvoiceGenerated from '@/features/tax/status/InvoiceGenerated'
import InvoiceGenerating from '@/features/tax/status/InvoiceGenerating'
import PreAssessed from '@/features/tax/status/PreAssessed'
import CasePurchased from '@/features/tax/status/Purchased'
import useFallbackCarLogo from '@/hooks/useFallbackCarLogo'
import { useDeleteFileFromTaxCase, useTaxCaseQuery } from '@/hooks/useTax'
import { copyTaxCaseToImportCase, uploadFileToTaxCaseURL } from '@/services/tax'
import { TaxCaseStoreProvider, useTaxCaseAssessment, useTaxCaseClient, useTaxCaseCreateAt, useTaxCaseDealerNote, useTaxCaseFlags, useTaxCaseId, useTaxCaseLinks, useTaxCaseMedia, useTaxCaseStatus, useTaxCaseVehicle } from '@/stores/taxCaseStore'
import { MediaResource } from '@/types/appTypes'
import { TaxCaseAssessment } from '@/types/taxTypes'
import axios from '@/utils/axios'
import { printMakeModelOrLink } from '@/utils/printHelpers'
import { formatDate, formatNumber, formatPrice } from '@/utils/string'
import { getStatusStepsCompleted, isAcceptedState, isAssessmentState, isCompletedState } from '@/utils/taxCaseStatus'
import { errorMessage } from '@/utils/toast'
import { isValidLink } from '@/utils/typeGuards'
import { Button, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Tab, TabGroup, TabList, TabPanel, TabPanels, Transition, TransitionChild } from '@headlessui/react'
import { ArrowDownTrayIcon, ArrowLongLeftIcon, ArrowLongRightIcon, ArrowPathIcon, DocumentIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, createFileRoute, useLoaderData, useLocation, useParams } from '@tanstack/react-router'
import { isAxiosError } from 'axios'
import clsx from 'clsx'
import { t } from 'i18next'
import { FormEvent, useState } from 'react'

export const Route = createFileRoute('/_protected/_tax/tax/$id/')({
    meta: ({ params }) => [{ title: `TAX · ${params.id}` }],
    component: TaxCase
})

function TaxCase() {
    const params = useParams({ from: "/_protected/_tax/tax/$id/" })
    const { data: taxCase, error } = useTaxCaseQuery(params.id)
    const [drawerOpen, setDrawerOpen] = useState(false);

    if (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
            return <NotFound />
        }
        return <GenericError />
    }

    if (!taxCase) {
        return <ContentLoading />
    }

    return (
        <TaxCaseStoreProvider taxCase={taxCase}>
            <Transition appear show as="div" className="transition duration-1000 data-[closed]:opacity-0 relative flex-1 flex flex-col">
                <CaseHeader openAcceptModal={() => setDrawerOpen(true)} />
                <TaxCaseCompleted />
                <div className='md:hidden p-4 -mt-4'>
                    <CaseStatus />
                </div>
                {/* Viewport: Mobile */}
                <TabGroup className="md:hidden p-4 pt-0">
                    <TabList className="mb-6 pb-2 flex flex-nowrap overflow-x-auto">
                        <Tab className="tab-btn">
                            {t("vehicle")}
                        </Tab>
                        <Tab className="tab-btn">
                            {t("assessment")}
                        </Tab>
                        <Tab className="tab-btn">
                            {t("valuation")}
                        </Tab>
                        <Tab className="tab-btn">
                            {t("documents")}
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <DetailedVehicleCard />
                        </TabPanel>
                        <TabPanel>
                            <AssessmentCard />
                        </TabPanel>
                        <TabPanel>
                            <PriceQuoteCard openAcceptModal={() => setDrawerOpen(true)} />
                        </TabPanel>
                        <TabPanel>
                            <DocumentsCard />
                        </TabPanel>
                    </TabPanels>
                </TabGroup>

                {/* Viewport: Desktop */}
                <section className='sm:py-8 py-4 sm:px-8 px-4 mx-auto w-full max-w-body max-md:hidden'>
                    <TaxCaseProgressTimeLine flags={taxCase.flags} status={taxCase.status} />
                </section>

                <TransitionChild>
                    <section className={clsx(
                        'sm:px-8 px-4 sm:pb-8 sm:pt-0 pt-4 pb-4 mx-auto w-full max-w-body',
                        'grid gap-4 items-start xl:grid-cols-3 md:grid-cols-2',
                        "max-md:hidden",
                        "transition duration-500 data-[closed]:translate-y-24"
                    )}>
                        <DetailedVehicleCard />

                        <div className="gap-4 grid items-start">
                            <AssessmentCard />
                            <CaseStatus />
                        </div>
                        <div className="gap-4 grid xl:grid-cols-1 md:grid-cols-2 xl:col-span-1 md:col-span-2 items-start">
                            <PriceQuoteCard openAcceptModal={() => setDrawerOpen(true)} />
                            <DocumentsCard />
                        </div>
                    </section>
                </TransitionChild>

                {/* Viewport width agnostic */}
                <BasicSlideOver open={drawerOpen} setOpen={setDrawerOpen} size='md'>
                    <div className='bg-white sm:p-8 p-4 overflow-y-auto h-full'>
                        <AcceptCase close={() => setDrawerOpen(false)} />
                    </div>
                </BasicSlideOver>

            </Transition>
        </TaxCaseStoreProvider>
    )
}

function CaseHeader({ openAcceptModal }: { openAcceptModal: () => void }) {
    const vehicle = useTaxCaseVehicle()
    const assessment = useTaxCaseAssessment()
    const status = useTaxCaseStatus()
    const caseid = useTaxCaseId()
    const client = useTaxCaseClient()
    const createdAt = useTaxCaseCreateAt()
    const { user } = useLoaderData({ from: "/_protected" })
    const [createLeasingCaseOpen, setCreateLeasingCaseOpen] = useState(false)

    const canAccept = !!assessment && isAssessmentState(status)

    const queryClient = useQueryClient()
    const { mutate: copyToImportCase, isPending: isCopyingToImport } = useMutation({
        mutationFn: () => copyTaxCaseToImportCase(caseid),
        onSuccess: (data) => {
            window.location.href = data.data.data.uri
            queryClient.invalidateQueries({ queryKey: ['user'] })
        },
        onError: (error) => errorMessage(error),
    })



    const loc = useLocation()
    // @ts-expect-error
    const prevPageSearch = loc.state.search;
    return (
        <header className='sm:p-6 p-4 md:bg-white md:shadow'>
            <div className="md:hidden mb-6">
                <Link to="/tax"
                    search={prevPageSearch ? { ...prevPageSearch } : { page: 1 }}
                    className="link font-normal text-sm"
                >
                    <ArrowLongLeftIcon aria-hidden="true" className="size-4 inline align-text-top mr-2" />
                    TAX
                    {' / '}
                    <span className='font-semibold'>
                        #{caseid}
                    </span>
                </Link>
            </div>
            <div className={clsx(
                "flex justify-between gap-6 max-md:flex-col-reverse",
                "max-md:p-6 max-md:items-center max-md:bg-white max-md:rounded-sm"
            )}>
                <div className="max-md:text-center">
                    <div className='md:hidden'>
                        <StatusTag {...status} />
                    </div>
                    <Link to="/tax"
                        search={prevPageSearch ? { ...prevPageSearch } : { page: 1 }}
                        className="link font-normal text-sm max-md:hidden"
                    >
                        <ArrowLongLeftIcon aria-hidden="true" className="size-4 inline align-text-top mr-2" />
                        TAX
                        {' / '}
                        <span className='font-semibold'>
                            #{caseid}
                        </span>
                    </Link>
                    <div className='flex gap-3 items-center mt-2 max-md:justify-center max-md:text-center'>
                        <h1 className="text-dark-blue text-xl font-medium">
                            {printMakeModelOrLink(vehicle?.brand, vehicle?.model, vehicle?.link)}
                        </h1>
                    </div>
                    <p className="text-text-secondary text-sm mt-2">
                        {vehicle?.vin && (<>
                            {vehicle?.vin}
                            <span className='inline-block mx-2 w-0 h-4 border-r align-bottom'></span>
                        </>)}
                        {vehicle?.firstRegDate && (<>
                            {formatDate(vehicle?.firstRegDate)}
                            <span className='inline-block mx-2 w-0 h-4 border-r align-bottom'></span>
                        </>)}
                        {vehicle?.mileage && formatNumber(vehicle?.mileage) + ' km.'}
                    </p>
                    <div className='mt-4 flex gap-4 max-md:justify-center items-center flex-wrap'>
                        {client && (
                            <p className='text-sm'>{t("createdBy")}: {client.name} d. {formatDate(createdAt)}</p>
                        )}
                        <span className='max-sm:hidden inline-block w-0 h-4 border-r'></span>
                        <span className="max-md:hidden"><StatusTag {...status} /></span>
                        <span className='max-sm:hidden inline-block w-0 h-4 border-r'></span>
                        <Menu>
                            <MenuButton disabled={!user.products.leasing && !user.products.import} className="link flex gap-1 items-center text-sm p-1 data-[disabled]:opacity-50">
                                <ArrowPathIcon className={clsx("size-4", isCopyingToImport ? "animate-spin block" : "hidden")} />  {t("moveTo")}
                            </MenuButton>
                            <MenuItems anchor={{ to: "bottom start", padding: 14, gap: 8 }}
                                className="origin-top-right rounded-sm border shadow bg-white py-0.5 text-sm transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                            >
                                {user.products.leasing && (
                                    <MenuItem>
                                        <Button onClick={() => setCreateLeasingCaseOpen(true)}
                                            className="block text-left px-4 py-1.5 w-full data-[focus]:bg-gray-100"
                                        >
                                            Leasing
                                        </Button>
                                    </MenuItem>
                                )}
                                {user.products.import && (
                                    <MenuItem>
                                        <Button
                                            onClick={() => copyToImportCase()}
                                            className="block text-left px-4 py-1.5 w-full data-[focus]:bg-gray-100"
                                        >
                                            Import
                                        </Button>
                                    </MenuItem>
                                )}
                            </MenuItems>
                        </Menu>
                    </div>
                    <Button className="btn btn-primary text-sm mt-6 md:hidden"
                        onClick={openAcceptModal}
                        disabled={!canAccept}
                    >
                        {getStatusStepsCompleted(status).acceptedState
                            ? t("accepted")
                            : `${t("payRegistrationFee", { context: "short" })}: ${formatPrice(assessment?.total)}`
                        }
                    </Button>
                </div>
                {vehicle?.image?.uri ? (
                    <div className='relative'>
                        <img loading="lazy"
                            src={vehicle.image.uri}
                            alt={vehicle.image?.name}
                            className='sm:max-h-24 sm:h-full sm:w-auto h-auto max-h-60 w-auto brightness-125'
                        />
                        <div className='absolute bottom-0 inset-x-0 bg-white h-[6%]'></div>
                    </div>
                ) : (
                    <CarLogo carBrand={vehicle?.brand ?? ""} />
                )}
            </div>
            <CopyToLeasing open={createLeasingCaseOpen} setOpen={setCreateLeasingCaseOpen} taxCaseId={caseid} />
        </header>
    )
}

function CarLogo({ carBrand }: { carBrand: string }) {
    const src = useFallbackCarLogo(carBrand);
    return (
        <img src={src}
            className="inline-block flex-none sm:h-full sm:w-auto sm:max-h-24 h-auto max-h-60 w-auto"
            alt={carBrand}
            loading="lazy"
        />
    )
}

function AcceptCase({ close }: { close: () => void }) {
    const status = useTaxCaseStatus()
    if (getStatusStepsCompleted(status).acceptedState) {
        return (
            <>
                <TaxCaseAccepted />
                <hr className="my-8" />
                <Button className="btn btn-gray w-full h-12" onClick={close}>
                    {t("close")} {t("window").toLowerCase()}
                </Button>
            </>
        )
    }

    return (
        <AcceptTaxCase closeSlideOver={close} />
    )
}

function DetailedVehicleCard() {
    const vehicle = useTaxCaseVehicle()
    const note = useTaxCaseDealerNote()
    if (!vehicle) {
        return (
            <div className='border rounded-sm text-center sm:p-8 p-4 bg-white'>
                <h3 className="text-lg font-semibold text-dark-blue">
                    {t("noVehicleData")}
                </h3>
            </div>
        )
    }

    return (
        <div className='border rounded-sm bg-white sm:p-6 p-4'>
            <h2 className='text-dark-blue' >{t("vehicle")}</h2>
            <hr className="my-4" />
            <DefinitionList items={[
                { label: t("link"), hideIfNoValue: true, value: isValidLink(vehicle.link) && <a className='link font-normal' target="_blank" href={vehicle.link}>{vehicle.link}</a> },
                { label: t("new") + "?", value: vehicle.isNew ? t("yes") : t("no") },
                { label: t("type"), value: vehicle.type?.name },
                { label: t("brand"), value: vehicle.brand },
                { label: t("model"), value: vehicle.model },
                { label: t("variant"), value: vehicle.variant },
                { label: t("firstRegDate", { context: "short" }), value: formatDate(vehicle.firstRegDate) },
                { label: t("vin"), value: vehicle.vin },
                { label: t("mileage"), value: formatNumber(vehicle.mileage) + " km" },
            ]} />

            <h3 className="font-semibold text-sm text-dark-blue mt-4 mb-2">Bildata</h3>
            <DefinitionList items={[
                { label: t("motorSize"), value: vehicle.ccEngineSize },
                { label: t("horsepower"), value: vehicle.horsePower },
                { label: t("color"), value: vehicle.color },
                { label: t("body"), value: vehicle.body?.name },
                { label: t("gears"), value: vehicle.gearbox?.name },
                { label: t("quality_vehicle"), value: vehicle.quality?.name },
                { label: t("specialUse"), value: vehicle.useCase?.name },
            ]} />

            <h3 className="font-semibold text-sm text-dark-blue mt-4 mb-2">Miljødata</h3>
            <DefinitionList items={[
                { label: t("fuel"), value: vehicle.fuel?.name },
                { label: t("fuelEfficiency"), value: vehicle.fuelEfficiency },
                { label: t("co2Emissions"), value: formatNumber(vehicle.emissionWltp) + " g/km" },
                { label: t("emissionUnit"), value: vehicle.emissionUnit?.name },
                { label: t("batteryCapacity"), value: formatNumber(vehicle.batteryCapacity) + " kWh" },
                { label: t("electricConsumption"), value: formatNumber(vehicle.electricConsumption) + " Wh/km" },
                { label: t("electricRange"), value: formatNumber(vehicle.electricRange) + " Km." },
            ]} />
            <div className='rounded-sm bg-gray-50 p-4 mt-6'>
                <p className="text-xs text-text-secondary">{t("note")}</p>
                <p className="text-xs text-dark-blue mt-1 whitespace-pre-wrap">{note}</p>
            </div>
        </div>
    )
}



function AssessmentCard() {
    const assessment = useTaxCaseAssessment()
    const vehicle = useTaxCaseVehicle()
    function grabPrice(key: keyof TaxCaseAssessment) {
        if (assessment) {
            return formatPrice(assessment[key], true)
        }
        return t("awaiting")
    }
    const items = [
        { label: t("calculationType"), value: vehicle?.type?.name },
    ]
    if (vehicle?.isNew) {
        items.push({ label: t("taxableValue"), value: grabPrice('taxableValue') })
    } else {
        items.push(
            { label: t("newPrice"), value: grabPrice('newPrice') },
            { label: t("tradePrice"), value: grabPrice("tradePrice") },
        )
    }
    items.push({ label: t("estimatedRegistrationFee"), value: grabPrice('estimatedRegistrationFee') })

    return (
        <div className='border rounded-sm bg-white sm:p-6 p-4 space-y-4'>
            <h2 className='text-dark-blue' >{t("preassessment")}</h2>
            <hr />
            <DefinitionList items={items} />
        </div>
    )
}


function CaseStatus() {
    const status = useTaxCaseStatus();
    const flags = useTaxCaseFlags();
    const taxCaseId = useTaxCaseId()
    const isExpired = status.value === getExpiredStatus()?.value;
    const isPreassessed = isAssessmentState(status)
    const isAccepted = isAcceptedState(status)
    const isGeneratingInvoice = flags?.invoiceStartedAt
    const hasGeneratedInvoice = flags?.invoiceDueAt
    const isPaid = !!flags?.paymentReceivedAt;
    const isCompleted = isCompletedState(status)
    if (isCompleted) return null;

    function renderInner() {
        if (isExpired) return <CaseExpired taxCaseId={taxCaseId} />;

        if (isPaid) return <CasePurchased />;
        else if (hasGeneratedInvoice) return <InvoiceGenerated />;
        else if (isGeneratingInvoice) return <InvoiceGenerating />;
        else if (isAccepted) return <CaseAccepted />;

        if (isPreassessed) return <PreAssessed />
        return <EmptyCalculationResult />;
    }
    return (
        <div className="border rounded-sm bg-white sm:p-6 p-4">
            {renderInner()}
        </div>
    )
}


type PriceQuoteCardprops = {
    openAcceptModal: () => void;
}
function PriceQuoteCard({ openAcceptModal }: PriceQuoteCardprops) {
    const assessment = useTaxCaseAssessment()
    const links = useTaxCaseLinks()
    const status = useTaxCaseStatus()

    function grabPrice(key: keyof TaxCaseAssessment) {
        if (assessment) {
            return formatPrice(assessment[key], true)
        }
        return t("awaiting")
    }
    const VATPrice = assessment?.total && assessment?.totalNoVat
        ? formatPrice(Math.ceil(assessment.total - assessment.totalNoVat), true)
        : t("awaiting")

    const items = [
        { label: t("estimatedRegistrationFee"), value: grabPrice("estimatedRegistrationFee") },
        { label: t("estimatedRiskFee"), value: grabPrice("estimatedCollateral") },
        { label: t("expeditionFee"), value: grabPrice("fee") },
        { label: t("numberPlates"), value: grabPrice("numberPlates") },
        { label: t("totalPrice"), value: grabPrice("total"), strong: true },
        { label: t("includingVat"), value: VATPrice },
    ]
    const canAccept = !!assessment && isAssessmentState(status)

    const downloadLinks = [
        { label: t("preassessment"), uri: links.offer?.uri, name: links.offer?.name },
        { label: t("invoice"), uri: links.invoice?.uri, name: links.invoice?.name },
        { label: t("receiptForValuation"), uri: links.receipt?.uri, name: links.offer?.name },
    ]

    return (
        <div className={clsx('border rounded-sm bg-white sm:p-6 p-4 space-y-4 relative')}>
            <h2 className='text-dark-blue' style={{ marginTop: 0 }} >{t("valuation")}</h2>
            <hr />
            <DefinitionList items={items} />
            <div className="flex gap-2 pt-2">
                {links.offer?.uri ? (
                    <a href={links.offer?.uri}
                        download={links.offer?.name}
                        target="_blank"
                        className="btn btn-outline size-10 p-0 flex-none"
                    >
                        <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                    </a>
                ) : (
                    <Button className="btn btn-outline size-10 p-0 flex-none" disabled>
                        <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                    </Button>
                )}
                <Button
                    onClick={() => openAcceptModal()}
                    disabled={!canAccept}
                    className="btn btn-primary w-full btn-lg text-sm"
                >
                    {
                        getStatusStepsCompleted(status).completedState ? `${t("caseCompleted")}!`
                            : getStatusStepsCompleted(status).purchasedState ? `${t("paid")}! ${t("awaitingValuation")}...`
                                : getStatusStepsCompleted(status).completedState ? `${t("caseCompleted")}!`
                                    : (
                                        <>
                                            {t("payRegistrationFee")}
                                            <ArrowLongRightIcon className='size-4' aria-hidden="true" />
                                        </>
                                    )}
                </Button>
            </div>
            <hr className="my-8" />
            <h3 className="text-sm mb-3">Downloads</h3>
            <ul className="space-y-2">
                {downloadLinks.map((link, i) => (
                    <li key={i} className="border px-4 rounded-sm h-[52px] flex gap-2 items-center">
                        <span className="text-sm">{link.label}</span>
                        {link.uri ? (
                            <>
                                <span className="text-xs py-1 px-2.5 text-green-500 border border-green-500 rounded-sm">
                                    {t("ready")}
                                </span>
                                <a className="ml-auto rounded-full link text-sm"
                                    href={link.uri}
                                    download={link.name}
                                    aria-label={link.name}
                                    target="_blank"
                                >
                                    {t("download")}
                                </a>

                            </>
                        ) : (
                            <span className="text-sm ml-auto">{t("notAvailable")}</span>
                        )}
                    </li>

                ))}
            </ul>
        </div>


    )
}

function DocumentsCard() {
    const queryClient = useQueryClient()
    const media = useTaxCaseMedia()
    const taxCaseId = useTaxCaseId()
    const mutateDelete = useDeleteFileFromTaxCase()
    const url = uploadFileToTaxCaseURL(taxCaseId)
    const [uploadPercent, setUploadedPercent] = useState<null | number>(null)
    async function onFileupload(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if ('files' in e.target && e.target.files) {
            const formData = new FormData()
            Array.from(e.target.files as FileList)
                .forEach((file) => {
                    formData.append("items[]", file)
                })
            try {
                await axios.post<{ data: MediaResource }>(url, formData, {
                    onUploadProgress(progressEvent) {
                        if (progressEvent.loaded && progressEvent.total) {
                            setUploadedPercent(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                        }
                    },
                });
                queryClient.invalidateQueries({ queryKey: ['taxCase', taxCaseId] })
            } catch (error) {
                errorMessage(error)
            } finally {
                setUploadedPercent(null)
            }


        }
    }
    async function onFileDelete(mediaUUID: string) {
        mutateDelete.mutate([taxCaseId, mediaUUID])
    }



    return (
        <div className='border rounded-sm bg-white sm:p-6 p-4 space-y-4'>
            <Disclosure as="div" className="w-full">
                <DisclosureButton className="w-full flex items-center justify-between">
                    {({ open }) => (
                        <>
                            <h2 className='text-dark-blue' >{t("documents")}</h2>
                            <span className={clsx("size-6 bg-gray-100 rounded-full flex items-center justify-center relative duration-200", open && "rotate-90")}>
                                <span className={clsx("absolute w-2 h-0.5 rounded-md bg-text-secondary", open && "rotate-90")}></span>
                                <span className={clsx("absolute w-0.5 h-2 bg-text-secondary transition-transform transform-cpu")}></span>
                            </span>
                        </>
                    )}
                </DisclosureButton>
                <div className="overflow-hidden">
                    <DisclosurePanel
                        className="origin-top transition pt-4 mt-4 border-t duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                        transition
                    >
                        <form
                            onInput={onFileupload}
                            className={clsx(uploadPercent && "pointer-events-none")}
                        >
                            <FileDragAndDrop name='upload-file' />
                            {uploadPercent && (
                                <>
                                    <progress className='sr-only' value={uploadPercent} max={100}></progress>
                                    <p className='text-sm mb-1 mt-4 font-semibold text-dark-blue'>{uploadPercent}%</p>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-clip">
                                        <div className="bg-primary h-2.5 rounded-full w-full transition-transform -translate-x-full" style={{
                                            transform: `translateX(${uploadPercent - 100}%)`,
                                        }}></div>
                                    </div>
                                </>
                            )}
                        </form>
                        <Spinner loading={mutateDelete.isPending}>
                            <ul className="space-y-2 mt-4">
                                {media && Object.values(media) && Object.values(media).map((media, i) => media && (
                                    <li key={i} className="bg-gray-50 p-4 rounded-sm flex gap-2 items-center">
                                        {renderFileExtensionMediaIcon(media.name)}
                                        <span className="text-xs font-medium truncate">{media.name}</span>
                                        <Button className="ml-auto flex-none rounded-full bg-red-100 text-red-500 size-8 flex items-center justify-center"
                                            aria-label={t("delete") + " " + media.name}
                                            onClick={() => onFileDelete(media.id)}
                                        >
                                            <TrashIcon className="size-5" aria-hidden="true" />
                                        </Button>
                                        <a className="rounded-full flex-none bg-primary/10 text-primary size-8 flex items-center justify-center"
                                            aria-label={"Download " + media.name}
                                            href={media.uri}
                                            target="_self"
                                            download={media.name}
                                        >
                                            <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </Spinner>
                    </DisclosurePanel>
                </div>
            </Disclosure>


        </div>
    )
}

function renderFileExtensionMediaIcon(filename: string) {
    if (/(\.png|\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.avif|\.gif|\.webp)$/i.test(filename)) {
        return <PhotoIcon className='size-5' aria-hidden="true" />
    }
    return <DocumentIcon className="size-5" aria-hidden="true" />
}




function TaxCaseCompleted() {
    const status = useTaxCaseStatus()
    if (isCompletedState(status)) {
        return (
            <div className='sm:p-6 p-4'>
                <CaseCompleted label={t("content:The_vehicle_has_now_been_valued")} />
            </div>
        )
    }
    return null
}
